import React from "react";
import styled from "styled-components";

const skills = {
    "Front-End & UI/UX": [
        { name: "JavaScript (ES6+)", icon: "🟨" },
        { name: "TypeScript", icon: "🟦" },
        { name: "React", icon: "⚛️" },
        { name: "React Hooks/Context", icon: "⚙️" },
        { name: "Redux", icon: "🔁" },
        { name: "React Query", icon: "🔎" },
        { name: "React Testing Library", icon: "🧪" },
        { name: "Jest", icon: "✔️" },
        { name: "HTML5", icon: "🌐" },
        { name: "CSS3", icon: "🎨" },
        { name: "Web Accessibility", icon: "♿" },
        { name: "React Native", icon: "📱" },
        { name: "Next.js", icon: "⏩" },
        { name: "NPM Package Development", icon: "📦" },
        { name: "Shared Component Libraries", icon: "📦" },
        { name: "Figma", icon: "🎨" },
    ],
    "Back-End & Full-Stack": [
        { name: "Integration Testing (Playwright)", icon: "🧪" },
        { name: "Node.js/Express.js", icon: "🌲" },
        { name: "Ruby on Rails", icon: "💎" },
        { name: "C# .NET Core", icon: "🔷" },
        { name: "REST APIs", icon: "📡" },
        { name: "WebSockets", icon: "🌐" },
        { name: "AWS (S3, Lambda, DynamoDB, CloudFormation)", icon: "☁️" },
        { name: "PostgreSQL", icon: "🐘" },
        { name: "SQL", icon: "📊" },
        { name: "Sequelize", icon: "🔗" },
        { name: "Micro-Service Architectures", icon: "🔗" },
        { name: "Working with Hardware", icon: "⚙️" },
    ],
    "Operational Tools": [
        { name: "Docker", icon: "🐋" },
        { name: "CI/CD", icon: "🔄" },
        { name: "Okta", icon: "🔐" },
        { name: "OAuth", icon: "🔑" },
        { name: "Jira", icon: "📋" },
        { name: "Git", icon: "🛠️" },
        { name: "Generative AI Tools", icon: "🤖" },
        { name: "OpenSearch", icon: "🔍" },
        { name: "PagerDuty (On-Call Rotations)", icon: "⏰" },
        { name: "Infrastructure as Code", icon: "🚜" }
    ],
};

const SkillsSection = styled.section`
  text-align: center;

  h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
`;

const SkillsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const SkillsCategory = styled.div`
  background: #f9f9f9;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;

  h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #333;
  }

  .skills-columns {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
        gap: 0; /* Remove gap between columns on mobile */
    }
  }

  ul {
    list-style: none;
    padding: 0;
    @media (max-width: 768px) {
      margin: 0; /* Remove gap between the 2 columns that only exist on desktop */
    }
    li {
      display: flex;
      align-items: center;
      margin-bottom: 0.75rem;
      font-size: 1rem;
      color: #555;

      .icon {
        margin-right: 0.75rem;
        font-size: 1.25rem;
      }
    }
  }
`;

const TechnicalSkills = () => {
    return (
        <SkillsSection>
            <SkillsGrid>
                {Object.entries(skills).map(([category, items]) => {
                    // Evenly split skills into two columns if there are more than 10
                    const half = Math.ceil(items.length / 2);
                    const firstColumn = items.slice(0, half);
                    const secondColumn = items.slice(half);

                    return (
                        <SkillsCategory key={category}>
                            <h3>{category}</h3>
                            <div className="skills-columns">
                                <ul>
                                    {firstColumn.map((skill, index) => (
                                        <li key={index}>
                                            <span className="icon">{skill.icon}</span>
                                            {skill.name}
                                        </li>
                                    ))}
                                </ul>
                                {secondColumn.length > 0 && (
                                    <ul>
                                        {secondColumn.map((skill, index) => (
                                            <li key={index}>
                                                <span className="icon">{skill.icon}</span>
                                                {skill.name}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </SkillsCategory>
                    );
                })}
            </SkillsGrid>
        </SkillsSection>
    );
};

export default TechnicalSkills;

import React from "react";
import { Button } from "@mui/material";
import { blue } from "@mui/material/colors";

const ResumeLinks = () => {
    return (
        <div style={{ width: '100%', display: "flex", gap: "16px" }}>
            <div>
                <Button
                    onMouseOver={(e) => {
                        e.target.style.backgroundColor = blue[50]; // Light blue background on hover
                    }}
                    onMouseOut={(e) => {
                        e.target.style.backgroundColor = 'white'; // Reset to white
                    }}
                    variant="outlined"
                    size="large"
                    href="/Bryan_Gottschalk_Resume_2025.docx"
                    target="_blank"
                    style={{
                        fontSize: "1rem",
                        color: blue[700], // Default MUI blue for text
                        borderColor: blue[700], // Default MUI blue for border
                        backgroundColor: 'white',
                        textTransform: 'none', // Optional: to keep text normal casing
                    }}
                >
                    Resume (DOCX)
                </Button>
            </div>
            <div>
                <Button
                    onMouseOver={(e) => {
                        e.target.style.backgroundColor = blue[50]; // Light blue background on hover
                    }}
                    onMouseOut={(e) => {
                        e.target.style.backgroundColor = 'white'; // Reset to white
                    }}
                    variant="outlined"
                    size="large"
                    href="/Bryan_Gottschalk_Resume_2025.pdf"
                    target="_blank"
                    style={{
                        fontSize: "1rem",
                        color: blue[700], // Default MUI blue for text
                        borderColor: blue[700], // Default MUI blue for border
                        backgroundColor: 'white',
                        textTransform: 'none', // Optional: to keep text normal casing
                    }}
                >
                    Resume (PDF)
                </Button>
            </div>
        </div>
    );
};

export default ResumeLinks;

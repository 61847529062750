import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig } from 'axios';
import awsConfig from '../../aws-exports';
import Amplify from 'aws-amplify';
import { Auth } from 'aws-amplify';

Amplify.configure(awsConfig);

interface UserState {
  isLoggedIn: boolean;
  loading: boolean;
  hasErrors: boolean;
  errorMsg: string;
  hasCheckedIfAlreadyLoggedIn: boolean;
}

const initialState: UserState = {
  isLoggedIn: false,
  loading: false,
  hasErrors: false,
  errorMsg: '',
  hasCheckedIfAlreadyLoggedIn: false
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state) => {
      state.loading = true;
    },
    loginSuccess: (
      state
      //{ payload }
    ) => {
      // localStorage.setItem('token', payload)
      state.isLoggedIn = true;
      state.loading = false;
      state.hasErrors = false;
    },
    loginFailure: (state, { payload }) => {
      state.isLoggedIn = false;
      state.loading = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    setIsLoggedOut: (state) => {
      localStorage.clear();
      state.isLoggedIn = false;
    },
    checkIsAlreadyLoggedIn: (state) => {
      const token = localStorage.getItem('portfolio-token');
      if (token && token.length > 300) {
        state.isLoggedIn = true;
      }
      state.hasCheckedIfAlreadyLoggedIn = true;
    }
  }
});

export const { login, loginSuccess, loginFailure, checkIsAlreadyLoggedIn } =
  userSlice.actions;

export const fetchLogin = createAsyncThunk(
  'user/fetchLogin',
  async (input: { username: string; password: string }, thunkAPI: any) => {
    const { username, password } = input;
    thunkAPI.dispatch(login());
    try {
      const res = await Auth.signIn({
        username: input.username,
        password: input.password
      });
      if (res) {
        localStorage.setItem(
          'portfolio-token',
          res.signInUserSession.accessToken.jwtToken
        );
        thunkAPI.dispatch(loginSuccess());
      } else {
        thunkAPI.dispatch(loginFailure('login failure'));
      }
    } catch (err) {
      thunkAPI.dispatch(loginFailure(err.message));
    }
  }
);

export const getTokenHeaders = (): AxiosRequestConfig => {
  const tokenInfo = localStorage.getItem('portfolio-token');
  if (tokenInfo) {
    const parsed = JSON.parse(tokenInfo); // as Token
    return {
      headers: { Authorization: `Bearer ${parsed.Token}` }
    };
  } else {
    throw new Error('no token found');
  }
};

export default userSlice.reducer;

import "./src/styles/global.css";
import React from "react";
import { Provider } from "react-redux";
import store from "./src/store";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

const theme = createTheme({
  typography: {
    fontFamily: "'Roboto', 'Arial', sans-serif",
  },
});

export const wrapRootElement = ({ element }) => {
  return <ThemeProvider theme={theme}><Provider store={store}>{element}</Provider></ThemeProvider>
};
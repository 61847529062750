import React from "react";
import styled from "styled-components";
import { Typography, Card, CardMedia, CardContent } from "@mui/material";
import cashier from '../assets/images/cashier.svg';
import kiosk from '../assets/images/kiosk.svg';
import web from '../assets/images/web.png';
import integrated from '../assets/images/integrated.png';

const GridContainer = styled.div`
  display: grid;
  gap: 16px;
  margin: 20px auto;
  padding: 16px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const products = [
    {
        title: "Kiosk",
        description:
            "Self-service option for city constituents to pay all of their bills in one place. Supports cash, card and check payments with kiosks stationed throughout the United States.",
        link: "https://thecitybase.com/products/kiosks",
        image: kiosk,
    },
    {
        title: "Cashiering / POS System",
        description:
            "Lead frontend developer and contributor of CityBase's Cashiering solution. Enables government cashiers to perform account lookups against their source systems and process payment transactions for constituents through a safe, secure and easy to use interface.",
        link: "https://thecitybase.com/products/cashiering",
        image: cashier
    },
    {
        title: "Online Payments",
        description:
            "Mobile-friendly and accessible e-commerce web application with cart and checkout. Accepts card and check payments. Functions similar to e-commerce sites.",
        link: "https://thecitybase.com/products/online-payments",
        image: web,
    },
    {
        title: "Revenue Management",
        description:
            "An integrated research, reporting, and reconciliation back office solution that comes standard with any CityBase product. Use Revenue Management for complete visibility into all payments across all channels and departments, in one place.",
        link: "https://thecitybase.com/products/revenue-management",
        image: integrated
    },
];

const InfoCard = () => {
    return (
        <>
            <Typography
                variant="h6"
                sx={{ fontWeight: "bold", marginBottom: "16px", fontSize: "1rem" }}
                textAlign="left"
            >
                <Typography>
                    Throughout my current role at CityBase (now Euna Payments), I have worked across 3 different engineering teams on the following products:
                </Typography>
                <GridContainer>
                    {products.map((product, index) => (
                        <Card
                            key={index}
                            sx={{
                                display: "flex",
                                flexDirection: { xs: "column", md: "row" },
                                justifyContent: "space-between",
                                gap: "16px",
                                padding: "16px",
                            }}
                        >
                            <CardMedia
                                component="img"
                                sx={{
                                    width: { xs: "100%", md: 150 },
                                    height: { xs: "auto", md: 150 },
                                    objectFit: "cover",
                                    borderRadius: "8px",
                                }}
                                image={product.image}
                                alt={product.title}
                            />
                            <CardContent
                                style={{ textAlign: "left" }}
                                sx={{ flex: 1, padding: { xs: "16px 0 0", md: "0 16px 0 0" } }}
                            >
                                <Typography
                                    variant="h6"
                                    component="a"
                                    href={product.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{
                                        textDecoration: "none",
                                        color: "primary.main",
                                        fontWeight: "bold",
                                        fontSize: "1.5rem",
                                        textAlign: "left",
                                    }}
                                >
                                    {product.title}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        marginTop: "8px",
                                        color: "text.secondary",
                                        textAlign: "left",
                                        fontSize: "0.8rem",
                                    }}
                                >
                                    {product.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    ))}
                </GridContainer>
            </Typography>
        </>

    );
};

export default InfoCard;
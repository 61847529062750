import React from "react";

const OutboundLink = ({ href, eventLabel, children, ...props }) => {
    const handleClick = (e) => {
        // Check if gtag is available
        if (typeof window !== "undefined" && window.gtag) {
            window.gtag("event", "click", {
                event_category: "outbound",
                event_label: eventLabel || href,
            });
        }
    };

    return (
        <a
            href={href}
            onClick={handleClick}
            target="_blank"
            rel="noopener noreferrer"
            {...props}
        >
            {children}
        </a>
    );
};

export default OutboundLink;
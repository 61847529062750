// src/components/Header.js
import React from 'react';
import OutboundLink from './OutboundLink';

const Header = () => (
  <header id="header" className="alt">
    {/* Uncomment and import logo if needed */}
    {/* <span className="logo">
      <img style={{ maxWidth: '6vw', minWidth: 100 }} src={logo} alt="Logo" />
    </span> */}
    <h1>Bryan Gottschalk</h1>
    <p style={{ marginBottom: '1em' }}>Software Engineer</p>
    <ul className="icons">
      <li>
        <OutboundLink
          href="https://github.com/bryangottschalk"
          eventLabel="GitHub Profile"
          className="icon fa-github alt"
        >
          <span className="label">GitHub</span>
        </OutboundLink>
      </li>
      <li>
        <OutboundLink
          href="https://www.linkedin.com/in/bryangottschalk/"
          eventLabel="LinkedIn Profile"
          className="icon fa-linkedin alt"
        >
          <span className="label">LinkedIn</span>
        </OutboundLink>
      </li>
      <li>
        <OutboundLink
          href="mailto:bryanlgottschalk@gmail.com"
          eventLabel="Send Email"
          className="icon fa-envelope alt"
        >
          <span className="label">Email</span>
        </OutboundLink>
      </li>
    </ul>
  </header>
);

export default Header;
